<template>
  <link
    rel="stylesheet"
    href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
  />
  <a
    :href="`https://api.whatsapp.com/send?phone=${telefone}&text=${texto}`"
    class="whatsapp-button"
    target="_blank"
  >
    <i class="fa fa-whatsapp custom-whatsapp-button"></i>
  </a>
</template>

<script setup>
defineProps({
  telefone: String,
  texto: String,
});
</script>

<script>
export default {
  name: "CustomWhatsappButton",
};
</script>
